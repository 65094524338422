<template>
  <!-- 同步大班课 -->
  <div class="addclass">
    <div class="content">
      <div class="cont-mid">
        <el-form ref="form" :rules="rules" :model="form" label-width="190px">
          <el-form-item label="课节名称：" prop="lessonsName">
            <el-input v-model="form.lessonsName" maxlength="100" show-word-limit style="width:555px"></el-input>
          </el-form-item>
          <el-form-item label="上课时间：" required>
            <dateTimePikcer @dateTimePicker="handleTimeChange"></dateTimePikcer>
            <el-tooltip class="item" effect="dark" content="请选择当前时刻起，30分钟后的时间点" placement="top"
              v-if="$route.query.courseType == 5">
              <i class="el-icon-question csp f20"></i>
            </el-tooltip>
            <!-- <el-col :span="4">
              <el-form-item prop="date">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  type="date"
                  placeholder="选择日期"
                  v-model="form.date"
                  style="width: 100%;"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5" style="margin-left:40px;">
              <el-form-item prop="during">
                <el-time-picker
                  is-range
                  v-model="form.time"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  value-format="HH:mm:ss"
                ></el-time-picker>
              </el-form-item>
            </el-col> -->
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="13">
              <el-form-item label="授课老师：" :required="true">
                <teacherSelect :requestType="'teacher'" :courseId="Number($route.params.id)"
                  @teacherSelect="handleTeacherChange" />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <teacherSelect :requestType="'assistant'" :courseId="Number($route.params.id)"
                @teacherSelect="handleTeacherChange" />
            </el-col>
          </el-row>

          <el-form-item label="教学模式：">
            <el-radio-group v-model="form.teachMode" @change="changeTeachMode">
              <el-radio :label="1">在线教室</el-radio>
              <el-radio :label="2">智慧教室</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="台上人数：">
                <el-select v-model="form.maxUsers" placeholder="1v2" filterable @change="changePlatformCount">
                  <el-option :label="item.name" :value="item.id" v-for="(item, index) in studentAmount" :key="index">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
                <el-form-item label="台下学生："  prop="maxBackupUsers" label-width="100px">
                    <el-input
                        type="maxBackupUsers"
                        v-model.number="form.maxBackupUsers"
                    ></el-input>
                </el-form-item>
            </el-col> -->
            <el-col :span="5">
              <el-form-item label="" :required="true" label-width="10px">
                <el-checkbox v-model="form.autoOnStage">学生进入教室时自动上台</el-checkbox>
              </el-form-item>

            </el-col>
          </el-row>

          <el-form-item label="直播回放：">
            <!-- <el-checkbox v-model="form.autoOnStage">网页直播</el-checkbox> -->
            <el-checkbox v-model="form.replay">网页回放</el-checkbox>
          </el-form-item>
          <!-- <el-form-item label="直播课件："  :required="true" > 
            <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" />
          </el-form-item> -->

          <el-form-item label="课节附件：">
            <uploadFile @uploadFile="handleFileChange" :name="'liveIdentifiers'" :sourceType=2 />
          </el-form-item>

          <!-- <el-form-item label="回放视频：">
            <uploadFile @uploadFile="handleFileChange" :name="'videoIdentifiers'" :tips="{image:false,url:true}" />
          </el-form-item> -->

          <el-form-item class="el-form-item-btns">
            <el-button @click="$router.push(`/lesson/${$route.params.id}?breadNumber=4`)">取消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>

          <br />
          <br />
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "newcourse",
    data() {
      var validateOffStage = (rule, value, callback) => {
        if (!value) {
          callback();
        }
        setTimeout(() => {
          if (!Number.isInteger(value)) {
            callback(new Error('请输入数字值'));
          } else {
            if (value > 300) {
              callback(new Error('台下人数不能大于300'));
            } else if (value < 0) {
              callback(new Error('台下人数不能小于0'));
            } else {
              callback();
            }
          }
        }, 1000);
      }
      return {
        form: {
          maxUsers: 14,
          autoOnStage: false,
          teachMode: 1, //教学模式  1在线教室  2智慧教室
          platformCount: 8, //台上人数  取 V 后面的值
          isHd: 0, // 是否高清 0 非高清 1高清 2全高清
          isAutoOnstage: 1, //学生进入教室是否自动上台 0自动 1不自动
          replay: false, //是否回访
        },
        submitForm: {
          teachMode: undefined,
          isHd: undefined,
          isAutoOnstage: undefined,
          assistantId: undefined,
          assistantRole: undefined,
          teacherId: undefined,
          teacherRole: undefined,
          lessonsBeginTime: undefined,
          lessonsEndTime: undefined,
          lessonsName: undefined,
          platformCount: undefined,
          liveIdentifiers: undefined,
        },
        rules: {
          lessonsName: [{
            required: true,
            message: "请输入课节名称",
            trigger: "blur"
          }],
          video: [{
            required: true
          }],
          maxBackupUsers: [{
            required: false,
            type: 'number',
            validator: validateOffStage
          }]
        },
        studentAmount: [{
            id: 1,
            value: 0,
            name: '1v0',
            isHd: 0
          },
          {
            id: 2,
            value: 1,
            name: '1v1',
            isHd: 0
          },
          {
            id: 3,
            value: 1,
            name: '1v1 (高清)',
            isHd: 1
          },
          {
            id: 4,
            value: 1,
            name: '1v1 (全高清)',
            isHd: 2
          },
          {
            id: 5,
            value: 1,
            name: '1v1 (双摄像头)',
            isHd: 0
          },
          {
            id: 6,
            value: 2,
            name: '1v2',
            isHd: 0
          },
          {
            id: 7,
            value: 3,
            name: '1v3',
            isHd: 0
          },
          {
            id: 8,
            value: 4,
            name: '1v4',
            isHd: 0
          },
          {
            id: 9,
            value: 5,
            name: '1v5',
            isHd: 0
          },
          {
            id: 10,
            value: 6,
            name: '1v6',
            isHd: 0
          },
          {
            id: 11,
            value: 6,
            name: '1v6 (高清)',
            isHd: 1
          },
          {
            id: 12,
            value: 6,
            name: '1v6 (全高清)',
            isHd: 2
          },
          {
            id: 13,
            value: 7,
            name: '1v7',
            isHd: 0
          },
          {
            id: 14,
            value: 8,
            name: '1v8',
            isHd: 0
          },
          {
            id: 15,
            value: 9,
            name: '1v9',
            isHd: 0
          },
          {
            id: 16,
            value: 10,
            name: '1v10',
            isHd: 0
          },
          {
            id: 17,
            value: 11,
            name: '1v11',
            isHd: 0
          },
          {
            id: 18,
            value: 12,
            name: '1v12',
            isHd: 0
          },
          {
            id: 19,
            value: 13,
            name: '1v13',
            isHd: 0
          },
          {
            id: 20,
            value: 14,
            name: '1v14',
            isHd: 0
          },
          {
            id: 21,
            value: 15,
            name: '1v15',
            isHd: 0
          },
        ]
      };
    },
    computed: {},
    components: {},
    watch: {},
    methods: {
      // 更改教学模式
      changeTeachMode(val) {
        // console.log(val);
        let map = {
          1: 14,
          2: 1
        }
        this.form.maxUsers = map[val];
        this.changePlatformCount(map[val]);
      },
      // 更改上台人数
      changePlatformCount(val) {
        let obj = this.studentAmount.find(item => item.id == val)
        this.form.platformCount = obj.value;
        this.form.isHd = obj.isHd;
      },
      handleTimeChange(data) {
        // console.log(data);
        this.form.lessonsBeginTime = data.bTime
        this.form.lessonsEndTime = data.eTime
        // console.log(this.form);
      },
      handleTeacherChange(data) {
        // console.log(data);
        let type = data.type === 'teacher' ? true : (data.type === 'assistant' ? false : '')
        if (type) {
          this.form.teacherId = data.teacherId
          this.form.teacherRole = data.role
        } else {
          this.form.assistantId = data.teacherId
          this.form.assistantRole = data.role
        }
      },
      handleFileChange(data) {
        // console.log(data);
        this.form[data.name] = data.fileMD5List;
      },
      // 拷贝需要提交的表单项
      copyForm() {
        for (const key in this.submitForm) {
          this.submitForm[key] = this.form[key];
        }

        Vue.set(this.submitForm, 'courseProjectId', this.$route.params.id)
        Vue.set(this.submitForm, 'isAutoOnstage', this.form.autoOnStage ? 0 : 1)

        // console.log('submitForm', this.submitForm);
      },
      async onSubmit() {
        // if (this.form.lessonsName.trim() == '') {
        //   this.$message.error('课程名称不能为空');
        //   return;
        // }
        this.$refs['form'].validate((valid) => {
          // console.log(valid);
          if (valid) {
            this.copyForm();
            this.commitForm();
          } else {
            this.$message.error('请正确填写数据');
          }
        })
      },
      async commitForm() {
        // this.form.courseProjectId = this.$route.params.id;
        // this.form.isAutoOnstage = this.form.autoOnStage ? 0 : 1
        // Vue.set(this.form,'autoOnStage',null)
        // Vue.set(this.form,'maxUsers',null)
        // console.log(this.form);
        // console.log(this.form);
        const loading = this.$loading(this.$store.state.user.loadingConfig);
        let resData = await this.$Api.Course.creatLesson(this.submitForm);
        if (resData.code === 200) {
          loading.close();
          this.$notify({
            title: "成功",
            message: "创建成功",
            type: "success"
          });
          setTimeout(() => {
            this.$router.push(`/lesson/${this.$route.params.id}?breadNumber=4`);
          }, 500);
        } else {
          loading.close();
          this.$notify.error({
            title: "错误",
            message: resData.msg
          });
        }
      },
    },
    created() {}
  };
</script>

<style lang="less" scoped></style>